import React from "react";
import firebase from "../config/firebase";

import {useParams} from "react-router-dom";


import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import PaidIcon from '@mui/icons-material/Paid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CancelIcon from '@mui/icons-material/Cancel';
import Cancel from "@mui/icons-material/Cancel";

const db = firebase.firestore();
const clubRef = db.collection("org").doc(process.env.REACT_APP_CLUB_ID);

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        Socios
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const Member = ({ history }) => {

  const {memberId} = useParams();
  const [Invoices, setInvoices] = React.useState([]);

  React.useEffect(() => {

    const listenMemberInvoices = () => {
        clubRef
          .collection('members')
          .doc(memberId)
          .collection('invoices')
          .orderBy("created", "desc")
          .onSnapshot((snapshot) => {
              setInvoices(snapshot.docs.map(x => ({...x.data(), id:x.id})));
             
        }, (error) => console.error(error));
    };

    listenMemberInvoices();

}, [memberId]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <PaidIcon />
                </Avatar>
                <Typography component="h1" variant="h5"  sx={{ mt: 3 }}>
                Mercado Pago a Old Ivy Club 
                </Typography>
                <Typography color="navy" sx={{mt: 3}}>
                 Facturas de {memberId}
                </Typography>
                <TableContainer component={Paper}  sx={{ mt: 3 }}>
                <Table sx={{ minWidth: "xs" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Concepto</TableCell>
                    <TableCell align="right">Monto</TableCell>
                    <TableCell align="right">Estado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Invoices.map((invoice) => (
                <TableRow
                    key={invoice.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell component="th" scope="row">
                    {invoice.title}
                  </TableCell>
                  <TableCell align="right">{invoice.amount}</TableCell>
                  <TableCell align="right">
                  {
                    {
                      "-2":<CancelIcon />,
                      "-1":<HelpIcon />,
                      "0": <HelpIcon color="danger"/>,
                      "1": <HourglassBottomIcon color="warning"/>,
                      "2": <CheckCircleIcon color="success"/>
                    }[invoice.state]
                  }
                  </TableCell>
                </TableRow>
                ))}
                 </TableBody>
              </Table>
            </TableContainer>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            </Container>
     </ThemeProvider>
);

}

export default Member;