import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Select, MenuItem, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Delete as DeleteIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';
import firebase from "../config/firebase.js";


const Administracion = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [activityFilter, setActivityFilter] = useState('');
  const [statusFilters, setStatusFilters] = useState({});

  const [actividades, setActividades] = useState([]);

  const estados = {
    '-1': 'Rechazado',
    '0': 'Pendiente Pago',
    '1': 'Conciliando por MP',
    '2': 'Conciliado'
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_ACTIVIDADES_URL);
        const data = await response.json();
        setActividades(data.actividades);
      } catch (error) {
        console.error('Error fetching actividades:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {

        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error('Usuario no autenticado.');
        }

        const idToken = await user.getIdToken();
        
        try {
            const response = await fetch(process.env.REACT_APP_INVOICES_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            const data = await response.json();
            setInvoices(data.invoices);
        } catch (error) {
        console.error('Error fetching invoices:', error);
        }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Filtrar facturas según los criterios de búsqueda
    let filtered = [...invoices];

    if (nameFilter.trim() !== '') {
      filtered = filtered.filter(invoice => invoice.member.firstName.toLowerCase().includes(nameFilter.toLowerCase()) ||
        invoice.member.lastName.toLowerCase().includes(nameFilter.toLowerCase()) ||
        invoice.member.identification.toLowerCase().includes(nameFilter.toLowerCase()));
    }

    if (activityFilter !== '') {
      filtered = filtered.filter(invoice => invoice.title === activityFilter);
    }

    if (Object.values(statusFilters).some(value => value)) {
      filtered = filtered.filter(invoice => {
        const status = invoice.state.toString();
        return statusFilters[status];
      });
    }

    filtered.sort((a, b) => b.created._seconds - a.created._seconds);
    setFilteredInvoices(filtered);
  }, [invoices, nameFilter, activityFilter, statusFilters]);

  const handleReject = async(id, member ) => {
    const user = firebase.auth().currentUser;
    if (!user) {
        throw new Error('Usuario no autenticado.');
    }
    const idToken = await user.getIdToken();
    const url = process.env.REACT_APP_REJECT_ORDER_URL
    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
        body: JSON.stringify({id, member})
        });

        if (!response.ok) {
        throw new Error('Error al rechazar la factura.');
        }

        const data = await response.json();
        window.location.reload();

    } catch (error) {
        console.error('Error al rechazar la factura:', error);
    }

  };

  const handleConciliate = async (id, member) => {
    const user = firebase.auth().currentUser;
    if (!user) {
        throw new Error('Usuario no autenticado.');
    }
    const idToken = await user.getIdToken();
    const url = process.env.REACT_APP_CONCILIATE_ORDER_URL
    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
        body: JSON.stringify({id, member})
        });

        if (!response.ok) {
        throw new Error('Error al conciliar la factura.');
        }

        const data = await response.json();
        window.location.reload();

    } catch (error) {
        console.error('Error al conciliar la factura:', error);
    }
  };

  const handleStatusFilterChange = (event, status) => {
    setStatusFilters({ ...statusFilters, [status]: event.target.checked });
  };

  const handleActivityFilterChange = (event) => {
    setActivityFilter(event.target.value);
  };

  function summarizeInvoices(filteredInvoices) {
    const summary = {
      totalAmount: 0,
      pendingCount: 0,
      paidCount: 0,
      otherCount: 0,
      titleSummary: {} // Objeto para almacenar la sumarización por título
    };
  
    filteredInvoices.forEach(invoice => {
      // Sumarizar el monto total
      summary.totalAmount += invoice.amount;
  
      // Contar por estado
      if (invoice.state === 0) {
        summary.pendingCount++;
      } else if (invoice.state === 2) {
        summary.paidCount++;
      } else {
        summary.otherCount++;
      }
  
      // Sumarizar por título
      const title = invoice.title;
      if (!summary.titleSummary[title]) {
        summary.titleSummary[title] = { totalAmount: 0, pendingCount: 0, paidCount: 0, otherCount: 0 };
      }
      summary.titleSummary[title].totalAmount += invoice.amount;
      if (invoice.state === 0) {
        summary.titleSummary[title].pendingCount++;
      } else if (invoice.state === 2) {
        summary.titleSummary[title].paidCount++;
      } else {
        summary.titleSummary[title].otherCount++;
      }
    });
  
    return summary;
  }

  // Suponiendo que tienes un array de facturas filtradas llamado `filteredInvoices`
  const summary = summarizeInvoices(invoices);
  
  // Ahora, `summary` contendrá la sumarización de las facturas filtradas por título y estado
  console.log(summary);
  
  
  return (
    <Box m={3}>
      <Typography variant="h4" gutterBottom>Administración</Typography>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <TextField
          label="Filtrar por Nombre o Identificación"
          variant="outlined"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel id="actividad-select-label">Filtrar por Actividad</InputLabel>
          <Select
            labelId="actividad-select-label"
            id="actividad-select"
            value={activityFilter}
            onChange={handleActivityFilterChange}
          >
            <MenuItem value="">Todas</MenuItem>
            {actividades.map((actividad) => (
              <MenuItem key={actividad.codigo} value={actividad.descripcion}>
                {actividad.descripcion}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <FormControl component="fieldset">
          <Typography variant="subtitle1">Filtrar por Estado</Typography>
          <FormGroup>
            {Object.entries(estados).map(([estado, descripcion]) => (
              <FormControlLabel
                key={estado}
                control={
                  <Checkbox
                    checked={statusFilters[estado] || false}
                    onChange={(event) => handleStatusFilterChange(event, estado)}
                  />
                }
                label={descripcion}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre, Apellido e Identificación</TableCell>
              <TableCell>Actividad</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Monto</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.map((invoice) => (
              <TableRow key={invoice.codigo}>
                <TableCell>{`${invoice.member.identification} - ${invoice.member.lastName}, ${invoice.member.firstName}`}</TableCell>
                <TableCell>{invoice.activity}</TableCell>
                <TableCell>{invoice.title}</TableCell>
                <TableCell>${invoice.amount}</TableCell>
                <TableCell>{estados[invoice.state]}</TableCell>
                <TableCell>
                  {invoice.state === 0 && (
                    <>
                      <IconButton color="error" onClick={() => handleReject(invoice.codigo, invoice.member.identification)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleConciliate(invoice.codigo, invoice.member.identification)}>
                        <CheckCircleIcon />
                      </IconButton>
                    </>
                  )}
                  {invoice.state === 1 && (
                    <>
                      <IconButton color="error" onClick={() => handleReject(invoice.codigo, invoice.member.identification)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Administracion;
