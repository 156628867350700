import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./Auth";


const PrivateRoute = ({ children }) => {

  const {currentUser} = useContext(AuthContext);
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
  
};

export default PrivateRoute;