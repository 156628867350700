import React, { useCallback, useState, useEffect } from "react";


import validate from 'validate.js'; 


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PaidIcon from '@mui/icons-material/Paid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import { MenuItem } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkout from "./Checkout";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        Socios
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const schema = {
  periodicity: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^La periodicidad es obligatoria", {
          num: value
        });
      } },
    length: {
      maximum: 32
    }
  },
  method: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^La modalidad es obligatoria", {
          num: value
        });
      } },
    length: {
      maximum: 32
    }
  },
  firstName: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^El nombre es obligatorio", {
          num: value
        });
      } },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
      return validate.format("^El email es obligatorio", {
        num: value
      });
    }},
    email: true,
    length: {
      maximum: 64
    }
  },
  lastName: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^El apellido es obligatorio", {
          num: value
        });
      } },
    length: {
      maximum: 32
    }
  },
  phone: {
    presence: { allowEmpty: false, 
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^El celular es obligatorio", {
          num: value
        });
      } },
    format: {
      pattern: /^09\d\d{6}$/,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^%{num} no es un número de celular válido", {
          num: value
        });
      }
    },
    length: {
      maximum: 9
    }
  },
  identification: {
    presence: { allowEmpty: false,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^La cédula es obligatoria", {
          num: value
        });
      } },
    format: {
      pattern: /^\d{7,8}$/,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^No es una cédula válida, debe ser solo números e incluir digito verificador", {
          num: value
        });
      }
    },
    length: {
      maximum: 8
    }
  },
};


const Payment = ({ history }) => {

  const [data, setData] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [order, setOrder]     = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [semestral, setSemestral] = useState(false);
  const [anual, setAnual] = useState(false);


  const [formState, setFormState] = useState({
    fromStep: 0,
    isValid: false,
    values: {
      firstName         : "",
      email             : "",
      lastName          : "",
      phone             : 0,
      identification    : 0,
      sport: "NULL",
      activity: "NULL",
      method : "NULL",
      periodicity : "NULL"
    },
    touched: {},
    errors: {}
  });

  const handlePayment = useCallback(async event => {

    event.preventDefault();
    const { email, phone, firstName, lastName, identification, activity, method, periodicity, sport } = event.target.elements;
    
    if (activity.value === "NULL") return;

    setLoadingMessage("Verificando información de pago");
    setLoading(true);

    const order = {
      activity      : activity.value,
      method        : method.value,
      periodicity   : periodicity.value,
      sport         : sport.value,
      payerPhone    : phone.value,
      payerEmail    : email.value,
      member : {
        firstName : firstName.value.toUpperCase(),
        lastName  : lastName.value.toUpperCase(),
        identification : identification.value
      }
    }

    const url = process.env.REACT_APP_CREATE_ORDER_URL;
    const response = await fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ order }),
    });
    const body   = await response.text();
    setLoadingMessage("Cargando información para el pago");
    const responseJson = JSON.parse(body);
    setOrderInfo({
      ...responseJson
    });
    setOrder(true);
    setLoading(false);
    setLoadingMessage("");
  });

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
    if (event.target.name === "activity") {
      const selectedCategoryData = data.find(item => item.actividad === formState.values.sport && item.codigo === event.target.value);
      console.log(event.target.value)
      if (selectedCategoryData) {
        setSemestral(selectedCategoryData.semestral);
        setAnual(selectedCategoryData.anual)
      } 
    }
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {

    const fetchData = async () => {

      const url = process.env.REACT_APP_ACTIVIDADES_URL;

      try {
        const response = await fetch(url);
        const jsonData = await response.json();
        setData(jsonData.actividades);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    
  }, [formState.values]);

  function RowRadioButtonsGroupMecanismo () {
    return (
      <FormControl>
        <FormLabel id="Method-group-label">Mecanismo</FormLabel>
        <RadioGroup
          row
          aria-labelledby="Method-group-label"
          name="method"
          value={formState.values.method}
          onChange={handleChange}
        >
          <FormControlLabel value="TRANSFERENCIA" control={<Radio />} label="Transferencia" />
          <FormControlLabel value="MERCADOPAGO" control={<Radio />} label="Mercado Pago" />
        </RadioGroup>
      </FormControl>
    );
  }
    
  function RowRadioButtonsGroupModalidad () {
    return (
      <FormControl>
        <FormLabel id="Periodicity-group-label">Modalidad</FormLabel>
        <RadioGroup
          row
          aria-labelledby="Periodicity-group-label"
          name="periodicity"
          value={formState.values.periodicity}
          onChange={handleChange}
        >
          <FormControlLabel value="ANUAL" control={<Radio />} label="Anual" disabled={!anual}/>
          <FormControlLabel value="SEMESTRAL" control={<Radio />} label="Semestral" disabled={!semestral}/>
        </RadioGroup>
      </FormControl>
    );
  }

  function RowRadioButtonsGroupDeporte () {
    return (
      <FormControl>
        <FormLabel id="Sport-group-label">Actividad</FormLabel>
        <RadioGroup
          aria-labelledby="Sport-group-label"
          name="sport"
          value={formState.values.sport}
          onChange={handleChange}
        >
          <FormControlLabel value="ESCUELITA" control={<Radio />} label="Escuelita / Academia" />
          <FormControlLabel value="FUTBOL" control={<Radio />} label="Fútbol" />
          <FormControlLabel value="HANDBALL" control={<Radio />} label="Handball" />
          <FormControlLabel value="HOCKEY" control={<Radio />} label="Hockey" />
          <FormControlLabel value="SOCIAL" control={<Radio />} label="Social" />
        </RadioGroup>
      </FormControl>
    );
  }

  function OptionsByDeporte() {
    return (
      <>
      <option key="NULL" value="NULL">Seleccione categoría...</option>
      {data.filter(item => item.actividad === formState.values.sport).map((category) => (
      <option key={category.codigo} value={category.codigo} disabled={!category.activa}>
        {category.activa ? category.descripcion : category.descripcion + " (proximamente)"}
      </option>
    ))}
      </>
    )
    
  }
  
  if (order && !loading) {
    return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PaidIcon />
          </Avatar>
          <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}>
              <CircularProgress color="inherit" />
          </Backdrop>
          <Typography component="h1" variant="h5">
            Pagos en Old Ivy Club
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Checkout orderInfo={orderInfo} />
          </Box>
          </Box>
        </Container>
      </ThemeProvider>  
    );      
  } else {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PaidIcon />
          </Avatar>
          <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}>
              <CircularProgress color="inherit" />
          </Backdrop>
          <Typography component="h1" variant="h5">
            Pagos en Old Ivy Club
          </Typography>
              <Box component="form" noValidate onSubmit={handlePayment} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Box></Box>
                <Typography color="navy" sx={{mt: 3}}>
                 Datos de Soci@
                </Typography>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      disabled={loading || order }
                      name="identification"
                      label="Cedula"
                      id="identification"
                      error={hasError('identificaton')}
                      helperText={
                        hasError('identification') ? formState.errors.identification[0] : null
                      }
                      onChange={handleChange}
                      value={formState.values.identification || ''}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || order}
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="Nombre"
                      error={hasError('firstName')}
                      helperText={
                        hasError('firstName') ? formState.errors.firstName[0] : null
                      }
                      onChange={handleChange}
                      value={formState.values.firstName || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      disabled={loading || order}
                      id="lastName"
                      label="Apellido"
                      name="lastName"
                      autoComplete="family-name"
                      error={hasError('lastName')}
                      helperText={
                        hasError('lastName') ? formState.errors.lastName[0] : null
                      }
                      onChange={handleChange}
                      value={formState.values.lastName || ''}
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {RowRadioButtonsGroupDeporte()}
                  </Grid>
                  <Grid item xs={12}>
                    <Select 
                        disabled={loading || order}
                        fullWidth native id="activity" label="Actividad" name="activity" 
                        onChange={handleChange}
                        value={formState.values.activity || ''}
                        >
                        {OptionsByDeporte()}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    {RowRadioButtonsGroupMecanismo()}
                  </Grid>
                  <Grid item xs={12}>
                    {RowRadioButtonsGroupModalidad()}
                  </Grid>
                  <Typography color="navy" sx={{mt: 3}}>
                  Datos de Responsable de Pago
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      disabled={loading || order}
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      error={hasError('email')}
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      onChange={handleChange}
                      value={formState.values.email || ''}
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      disabled={loading || order}
                      id="phone"
                      label="Celular"
                      name="phone"
                      autoComplete="phone"
                      error={hasError('phone')}
                      helperText={
                        hasError('phone') ? formState.errors.phone[0] : null
                      }
                      onChange={handleChange}
                      value={formState.values.phone || ''}
                      
                    />
                  </Grid>
                  
                </Grid>
                {!order && !loading && <Button 
                  type="submit"
                  id="button-checkout"
                  fullWidth
                  variant="contained"
                  disabled={!formState.isValid || (formState.values.activity === "NULL")}
                  sx={{ mt: 3, mb: 2 }}
                > 
                Comenzar pago
                </Button>}
                {loading &&  <Typography variant="caption" display="block" gutterBottom>
                {loadingMessage}
                </Typography>}
              </Box>
          </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )};
}

export default Payment;