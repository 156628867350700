import React, { useEffect } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';


const Checkout = (props) => {

    let orderInfo   = props.orderInfo;  

    const handleReject = async () => {
        const url = process.env.REACT_APP_REJECT_ORDER_URL
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: orderInfo.id, member: orderInfo.member.identification })
          });
    
          if (!response.ok) {
            throw new Error('Error al rechazar la factura.');
          }
    
          const data = await response.json();
          window.location.reload(); // Recarga la página

        } catch (error) {
          console.error('Error al rechazar la factura:', error);
        }
      };

    const mercadopago = useMercadopago.v2(process.env.REACT_APP_MERCADOPAGO_TOKEN, {
        locale: 'es-UY'
    });

    useEffect(() => {
        if (mercadopago) {
            mercadopago.checkout({
                preference: {
                    id: orderInfo.id
                },
                render: {
                    container: '.cho-container',
                    label: 'Pagar',
                }
            })
        }
    }, [mercadopago, orderInfo])

    if (!mercadopago)
        return (
            <Box  sx={{ mt: 3 }}>
                <>Cargando...</>
            </Box>
        );

    const mensaje = `Este mensaje automático se generó para realizar la transferencia para el socio con documento *${orderInfo.member.identification}* correspondiente al concepto *${orderInfo.title}* por un importe de *${orderInfo.amount}$*.\n\nLa transferencia se debe realizar a cuenta del Old Ivy Club en ITAU con número *4083031*, colocando en la referencia el documento *${orderInfo.member.identification}*.\n\nRecuerde enviar comprobante por este medio una vez realizada la transferencia.`;
    const mensajeCodificado = encodeURIComponent(mensaje);
    const whatsappLink = `https://wa.me/59894621688?text=${mensajeCodificado}`;
    
    if (mercadopago) {
        if (orderInfo.exists) {
            return (
                <Box  sx={{ mt: 3 }}>
                <Typography variant="body1" display="block" gutterBottom>
                    Hola {orderInfo.member.firstName}, ya existe una orden creada para usted pendiente de pago. <br />
                    Presioná enviar para comunicarte por whatsapp con la Administración de Old Ivy Club y obtener instrucciones para la transferencia.
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom>
                {orderInfo.title} por un total de {orderInfo.amount}$.
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                Si queres anular este pago, hace clic en Rechazar
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="contained"  color="success"><Link href={whatsappLink} underline="none" color="inherit">Enviar</Link></Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button onClick={handleReject} color="error">Rechazar</Button>
                    </Grid>
                </Grid>
                </Box>
            )
        }
       
        if (orderInfo.method === "MERCADOPAGO") {
            return (<Box  sx={{ mt: 3 }}>
                <Typography variant="body1" display="block" gutterBottom>
                    Haga clic en el siguiente botón para pagar a Old Ivy Club. 
                    En la plataforma de Mercado Pago podrá elegir la forma de pago y en caso de crédito en cuantas cuotas sin interés.
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom>
                    {orderInfo.title} por un total de {orderInfo.amount}$.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box className="cho-container" xs={12}></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="error" variant="text" onClick={handleReject}>Rechazar</Button>
                    </Grid>
                </Grid>
                
               
            </Box>
            )
        
        } else {

            return (
                <Box  sx={{ mt: 3 }}>
                <Typography variant="body1" display="block" gutterBottom>
                    Hola {orderInfo.member.firstName}, presioná enviar para comunicarte por whatsapp con la Administración de Old Ivy Club y obtener instrucciones para la transferencia.
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom>
                {orderInfo.title} por un total de {orderInfo.amount}$.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="contained"  color="success"><Link href={whatsappLink} underline="none" color="inherit">Enviar</Link></Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="error" variant="text" onClick={handleReject}>Rechazar</Button>
                    </Grid>
                </Grid>
                </Box>
            )
        }
    }
}

export default Checkout;