import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import firebase from "./config/firebase.js";

export const AuthContext = React.createContext();


export const AuthProvider = ({ children }) => {

  const club = process.env.REACT_APP_CLUB_ID;

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  //const [emailVerified, setEmailVerified] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      if(user) {
        function fetchDataUser() {
          firebase.firestore().collection('org').doc(club)
            .collection("users")
            .doc(user.uid)
            .get()
            .then(function (doc) {
              if (doc.exists) {
                setUserProfile({...doc.data()});
                setLoading(false);
              }
            }).catch(function (error) {
              console.log("Error getting  document:", error);
            });
        }
        fetchDataUser();
      }
    });

  }, [club]);

  if(loading){
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  } 

  return (
    <AuthContext.Provider
      value={{
        currentUser, userProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};