import React, { useState , useEffect, useContext} from "react";
import firebase from "../config/firebase";
import { AuthContext } from '../Auth.js'
import { IconButton, TableCell, Tooltip } from "@mui/material";
import Moment from 'react-moment';


import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';


function ActividadCupos (props) {

    const club = process.env.REACT_APP_CLUB_ID;

    const [libres, setLibres] = useState(0);
    const {currentUser} = useContext(AuthContext);

    const [userEnActividad, setUserEnActividad] = useState(null);
    
    useEffect(() => {
        
        const listenForActividadCupos = () => {
                firebase.firestore().collection('org').doc(club)
                  .collection('actividades')
                  .doc(props.actividad.id)
                  .collection('users')
                  .onSnapshot((snapshot) => {
                    setUserEnActividad(false)
                    snapshot.docs.forEach(function(doc) {
                      if (doc.id === currentUser.uid)
                      setUserEnActividad(true)
                    });
                    setLibres((props.actividad.cupos - snapshot.docs.length));
                }, (error) => console.error(error));
       };
       
       listenForActividadCupos();  
      }, [club, props.actividad]);
      
    const handleCancelar = () => {
        const db = firebase.firestore().collection('org').doc(club);
      
        const uidRef = db.collection('users').doc(currentUser.uid)
        const userActividadRef = uidRef.collection('actividades').doc(props.actividad.id);
        
        const actividadRef = db.collection('actividades').doc(props.actividad.id);
        const usrRef = actividadRef.collection('users').doc(currentUser.uid);
                
        return firebase.firestore().runTransaction(function(transaction) {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(actividadRef).then(function(actDoc) {
                if (!actDoc.exists) {
                    throw "Actividad  does not exist!";
                }
                transaction.delete(usrRef);   
                transaction.delete(userActividadRef);
                })
        }).then(function() {
        }).catch(function(error) {
            console.log("Transaction failed: ", error);
        });
    }

    const handleReservar = () => {
        const db = firebase.firestore().collection('org').doc(club);
      
        const uidRef = db.collection('users').doc(currentUser.uid)
        const userActividadRef = uidRef.collection('actividades').doc(props.actividad.id);
        const actividad = {
            'created' : firebase.firestore.FieldValue.serverTimestamp(),
        }

        const actividadRef = db.collection('actividades').doc(props.actividad.id);
        const usrRef = actividadRef.collection('users').doc(currentUser.uid);
        const user = {
            'created' : firebase.firestore.FieldValue.serverTimestamp()
        } 
        
                
        return firebase.firestore().runTransaction(function(transaction) {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(actividadRef).then(function(actDoc) {
                if (!actDoc.exists) {
                    throw "Actividad  does not exist!";
                }
                transaction.set(usrRef, user);   
                transaction.set(userActividadRef, actividad);
                })
        }).then(function() {
        }).catch(function(error) {
            console.log("Transaction failed: ", error);
        });
    }

    var inicioReserva = new Date(props.actividad.fecha.toDate());
    inicioReserva.setDate(inicioReserva.getDate()-1);
    
    const today = new Date();
    
    var diffMs = (inicioReserva - today);
    
    if (diffMs > 0)
        return (
            <>
            <TableCell>{props.actividad.cupos} cupos</TableCell>
            <TableCell>Reservas <Moment fromNow>{inicioReserva}</Moment></TableCell>
            </>
        )

    
    if (userEnActividad)
      return (
        <>
         <TableCell>{libres} de {props.actividad.cupos} <br />Te esperamos!</TableCell>
         <TableCell>
            <Tooltip title="Cancelar">
                <IconButton onClick={handleCancelar} >
                    <EventBusyIcon color="error"/>
                </IconButton>
           </Tooltip>
         </TableCell>
       </>
      )

    if (libres > 0) 
        return (
            <>
            <TableCell> {libres} de {props.actividad.cupos}</TableCell>
            <TableCell>
            <Tooltip title="Reservar">
                    <IconButton onClick={handleReservar} >
                        <EventAvailableIcon color="success"/>
                    </IconButton>
            </Tooltip>
            </TableCell>
        </>
        )

        return (
            <>
            <TableCell> {libres} de {props.actividad.cupos}
            </TableCell>
            <TableCell>
            </TableCell>
        </>
        )

};

export default ActividadCupos;