import React, { useState, useEffect , useContext} from 'react';
import { Grid, Paper, Button, LinearProgress, Container } from '@mui/material';
import { AuthContext } from '../Auth.js';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Card, 
  CardMedia,
  CardActions,
  CardContent,
  CircularProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';

import firebase from "../config/firebase.js";
import Copyright from './Copyright.js';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';

// Función para verificar si la pantalla es un iPad o mayor
const isIPadOrBigger = () => {
  return window.innerWidth >= 768; // El ancho mínimo para un iPad
};

// Componente para la vista resumida por actividad
const ResumenActividades = ({ data, onActividadClick }) => {
  return (
    <Grid container spacing={3}>
      {Object.keys(data).map((actividad) => (
        <Grid item xs={6} key={actividad}>
          <Card>
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {actividad}
            </Typography>
            <Grid container spacing={2} justifyContent="center"  alignItems="center">
              <Grid item xs={6}>
                <Grid container  justifyContent="center"  alignItems="center">
                  <Grid item xs={12}>
                  {data[actividad].paidAmount}
                  </Grid>
                  <Grid item xs={12}>
                  $ Recaudados
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={6}>
                <Grid container  justifyContent="center"  alignItems="center"> 
                  <Grid item xs={12}>
                  {data[actividad].expectedAmount}
                  </Grid>
                  <Grid item xs={12}>
                  $ Presupuest.
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
              <Grid container justifyContent="center"  alignItems="center">
                  <Grid item xs={12}>
                  {data[actividad].sociosInscriptos}
                   </Grid>
                  <Grid item xs={12}>
                  # Inscriptos.
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                    {data[actividad].sociosExpected}
                     </Grid>
                    <Grid item xs={12}>
                    # Esperados
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container   justifyContent="center"  alignItems="center"> 
                  <Grid item>
                    {((data[actividad].paidAmount / data[actividad].expectedAmount) * 100).toFixed(2)}% de Avance
                  </Grid>
                </Grid>
              </Grid>
              </Grid>
              <div style={{ flex: 1 }}>
                <div style={{ width: '100%', height: 200 }}>
                  <ResponsiveContainer>
                    <BarChart data={[{ name: 'Pagado', esperado: data[actividad].expectedAmount, pagado: data[actividad].paidAmount }]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="esperado" fill="#8884d8" name="Esperado" />
                      <Bar dataKey="pagado" fill="#82ca9d" name="Pagado" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => onActividadClick(actividad)} style={{ marginLeft: '10px' }}>Ver Detalles</Button>
          </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

// Componente para el detalle por categoría
const DetalleCategoria = ({ actividadSeleccionada, data }) => {
  const categoriasActividad = data[actividadSeleccionada].categorias;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>{actividadSeleccionada}</Typography>
      </Grid>
      {categoriasActividad.map((categoria) => (
        <Grid item xs={6} key={categoria.id}>
          <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          {categoria.descripcion}
          </Typography>
          <Grid container spacing={2} justifyContent="center"  alignItems="center">
            <Grid item xs={6}>
              <Grid container  justifyContent="center"  alignItems="center">
                <Grid item xs={12}>
                {categoria.paidAmount}
                </Grid>
                <Grid item xs={12}>
                $ Recaudados
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={6}>
              <Grid container  justifyContent="center"  alignItems="center"> 
                <Grid item xs={12}>
                {categoria.expectedAmount}
                </Grid>
                <Grid item xs={12}>
                $ Presupuest.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
            <Grid container justifyContent="center"  alignItems="center">
                <Grid item xs={12}>
                {categoria.sociosInscriptos}
                  </Grid>
                <Grid item xs={12}>
                # Inscriptos.
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                  <Grid item xs={12}>
                  {categoria.sociosExpected}
                    </Grid>
                  <Grid item xs={12}>
                  # Esperados
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container   justifyContent="center"  alignItems="center"> 
                <Grid item>
                  {((categoria.paidAmount / categoria.expectedAmount) * 100).toFixed(2)}% de Avance
                </Grid>
              </Grid>
            </Grid>
            </Grid>
            <div style={{ flex: 1 }}>
              <div style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                  <BarChart data={[{ name: 'Pagado', esperado: categoria.expectedAmount, pagado: categoria.paidAmount }]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="esperado" fill="#8884d8" name="Esperado" />
                    <Bar dataKey="pagado" fill="#82ca9d" name="Pagado" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </CardContent>
        </Grid>
      ))}
    </Grid>
  );
};

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const handlelogout = () => {
  firebase.auth().signOut().then(function() {
  }).catch(function(error) {
      console.log(error)
  });
};


// Componente ClubDashboard
const ClubDashboard = () => {

  const theme = useTheme();

  const club = process.env.REACT_APP_CLUB_ID;
  const [clubData, setClubData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const {userProfile} = useContext(AuthContext);
 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState([]);
  const [selectedActividad, setSelectedActividad] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('Usuario no autenticado.');
      }

      const idToken = await user.getIdToken();

      try {
        const response = await fetch(process.env.REACT_APP_ACTIVITY_SUMMARY_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          }
        });
        if (!response.ok) {
          throw new Error('No se pudo obtener la data');
        }
        const jsonData = await response.json();
        setData(jsonData.activity_summary);
      } catch (error) {
        console.error('Error al obtener la data:', error);
      }
    };
    fetchData();
  }, []);

  const handleActividadClick = (actividad) => {
    setSelectedActividad(actividad);
  };

  React.useEffect(() => {
    const fetchDataClub = () => {
      const db = firebase.firestore().collection('org').doc(club);
      db.get()
      .then(function(doc) {
          if (doc.exists) {
              setClubData({...doc.data()});
          } else {
              console.log("no existen la organizacion")
          }
      });
    }
    fetchDataClub();
  }, [club]);

  let socio = "Socio";
  if (userProfile)
    socio = userProfile.firstName + " " + userProfile.lastName;

  let clubNombre = "Club";
  if (clubData) 
    clubNombre = clubData.nombre;

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            {clubNombre} | {socio}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4" gutterBottom>Estado de actividades y categorías</Typography>
            <div>
              {selectedActividad && (
                <Button variant="outlined" onClick={() => setSelectedActividad(null)} style={{ marginLeft: '20px' }}>Volver</Button>
              )}
            </div>
          </Grid>
          {!selectedActividad && (
            <ResumenActividades data={data} onActividadClick={handleActividadClick} />
          )}
          {selectedActividad && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
              </Grid>
              <DetalleCategoria actividadSeleccionada={selectedActividad} data={data} />
            </Grid>
          )}
        </Container>
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton  component="a" href="/administracion/dashboard">
              <ListItemIcon>
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  component="a" href="/administracion/conciliacion">
              <ListItemIcon>
                <AccountBalanceIcon/>
              </ListItemIcon>
              <ListItemText primary="Conciliación" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  onClick={() => handlelogout()}>
              <ListItemIcon>
                <LogoutIcon/>
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
    <Copyright sx={{ mt: 8, mb: 4 }} />
    </>
  );
};

export default ClubDashboard;
