
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Auth";
import CacheBuster from 'react-cache-buster';
import Moment from 'react-moment';
import { CircularProgress } from '@mui/material';




import Payment from "./components/Payment"
import Member from "./components/Member";
import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import Administracion from "./components/Administracion";
import ClubDashboard from "./components/ClubDashboard";


import 'moment/locale/es';
Moment.startPooledTimer();


function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  const version = '2.0.1';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CircularProgress />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<p>Index!</p>} /> 
          <Route path="/payment" element={<Payment />} />
          <Route path="/member/:memberId/"  element={<Member />} />
          <Route path="*" element={<p>There's nothing here: 404 xxxx!</p>} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="home" element={<Dashboard  />} />
            <Route path="/administracion/conciliacion" element={<Administracion />} />
            <Route path="/administracion/dashboard" element={<ClubDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </CacheBuster>
  );
}
 
export default App;
