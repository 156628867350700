import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import firebase from "../config/firebase";

import validate from 'validate.js'; 


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from "./Copyright.js";


const theme = createTheme();

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'es obligatorio' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'es obligatorio' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'es obligatorio' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'es obligatorio' },
    length: {
      maximum: 128
    }
  },
};


const SignUp = ({ history }) => {

  let navigate = useNavigate();

  const club = process.env.REACT_APP_CLUB_ID;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstName : "",
      lastName  : "",
      email     : "",
      password  : "",
    },
    touched: {},
    errors: {}
  });
  
  const handleSignUp = useCallback(async event => {
    event.preventDefault();
    const { email, firstName, lastName, password } = event.target.elements;
    const userProfile = {
      email       : email.value.toUpperCase(),
      firstName   : firstName.value.toUpperCase(),
      lastName    : lastName.value.toUpperCase(),
      created     : firebase.firestore.FieldValue.serverTimestamp()
    }
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then(
          (userCredential) => {
            if(userCredential.user){
              firebase.firestore().collection('org').doc(club)
                .collection('users')
                  .doc(userCredential.user.uid)
                  .set(userProfile)
                  .then(function() {
                  }).catch(function(error) {
                      console.log("Transaction failed: ", error);
                  });
            }
            //userCredential.user.sendEmailVerification();
        });
        navigate('/home');
      } catch (error) {
        alert(error.message);
      }
    }, [club, navigate]
  );

    const handleChange = event => {
      event.persist();
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    };

    const hasError = field =>
      formState.touched[field] && formState.errors[field] ? true : false;
  

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registrarse
          </Typography>
          <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Nombre"
                  error={hasError('firstName')}
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  onChange={handleChange}
                  value={formState.values.firstName || ''}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Apellido"
                  name="lastName"
                  autoComplete="family-name"
                  error={hasError('lastName')}
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  onChange={handleChange}
                  value={formState.values.lastName || ''}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  onChange={handleChange}
                  value={formState.values.email || ''}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={hasError('password')}
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  onChange={handleChange}
                  value={formState.values.password || ''}
                  
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Confirmar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Ya tienen cuenta? Haz clic para ingresar
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignUp;