
import React from 'react';
import firebase from "../config/firebase";
import Moment from 'react-moment';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Container,
    Typography,
    Divider,
    CircularProgress,
    Button
  } from '@mui/material';
import ActividadCupos from './ActividadCupos';

 const verFecha = (fecha) => {
    return (<Moment format="DD/MM HH:mm">{fecha.toDate()}</Moment>)
 } ;


function Actividades (props) {

    const club = process.env.REACT_APP_CLUB_ID;
    const [actividades, setActividades] = React.useState(null);

    var now = new Date();
    now.setHours(0,0,0,0);
    var auxNow = new Date(now);
    var auxEnd = new Date(now);
    auxEnd.setDate(auxEnd.getDate()+7);
    const [filterDateStart, setFilterDateStart] = React.useState(auxNow);
    const [filterDateEnd, setFilterDateEnd]     = React.useState(auxEnd);



    React.useEffect(() => {
        setActividades(null)
        const fetchDataSimple = async () => {
        const db = firebase.firestore().collection('org').doc(club);
        const data = await db.collection("actividades")
                .where("fecha", ">=", filterDateStart)
                .where("fecha", "<=", filterDateEnd)
                .get();
            setActividades(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };  
        
        fetchDataSimple();
    }, [filterDateEnd, filterDateStart, club]);
   
    if (!actividades)
        return (
            <CircularProgress />
        );

    if (!actividades.length)
        return (
            <Container>
                <Typography variant="h5">
                Próximas actividades con cupos
                 </Typography>
                <Divider />
                <Typography variant="body">
                    No existen actividades con cupos en los próximos días.
                </Typography>
            </Container>
        );
    
    return (
        <Container >
            <Typography variant="h5">
                Próximas actividades con cupos
            </Typography>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Turno
                </TableCell>
                <TableCell>
                  Actividad
                </TableCell>
                <TableCell>
                  Cupos Libres
                </TableCell>
                <TableCell>
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(actividades).map(
                    (key, id ) => (
                        <TableRow
                            hover
                            key={id}
                            >
                            <TableCell>
                                {verFecha(actividades[id].fecha)}
                            </TableCell>
                            <TableCell>
                                {actividades[id].descripcion}
                            </TableCell>
                            <ActividadCupos actividad={actividades[id]} />
                        </TableRow>
                    ))
                }
            </TableBody>
          </Table>
          <br />
          <Typography variant='normal'>
            Si quieres puedes unirte al grupo de whatsapp de Funcional para estar al tanto y en mas sintonía con el resto del grupo
          </Typography>
          <Button variant="contained" href="https://chat.whatsapp.com/Dm459wTAyPm9PnWGJ7sAXt">
            Unirse a whatsapp
            </Button>
        </Container>
    )
};

export default Actividades;